import * as ENV from '../ENV';

export const createOrder = (owner, items) => {
    return fetch(ENV.API + 'order', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "items": items,
            "owner": owner
        })
    }).then((resp) => {
        console.log(resp);
        return resp;
    })
}

export const confirmOrder = (order, user, bdOrder) => {
    return fetch(ENV.API + 'pp', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "order": order,
            "owner": user,
            "bdOrder": bdOrder

        })
    }).then((resp) => {
        console.log(resp);
        return resp;
    })
}

export const getOrder = async (id) => {
    return fetch(ENV.API + 'order/'+id, {
        method: 'GET',
        headers: {
        },
    }).then((resp) => {
        return resp
    });
};

export const getPpOrder = async (id) => {
    return fetch(ENV.API + 'pp/approved/'+id, {
        method: 'GET',
        headers: {
        },
    }).then((resp) => {
        return resp
    });
};




