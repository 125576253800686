import * as ENV from '../ENV';

export const resetPw = (email) => {
    return fetch(ENV.API + 'users/reset', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "email": email,
        })
    }).then((resp) => {
        return resp;
    })
}
