import React from "react";
import '../App.css';
import logo from "../img/blackdoor_white.jpeg";
import _ from 'lodash'
import {resetPw} from "./UserService";

class ResetScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {email: '', reset: false};
    }

    componentDidMount() {
    }

    inputValid(){
        return this.state.email && this.state.email !== '' && this.state.email !== "";
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    reset(){
        if(this.state.email) {
            resetPw(this.state.email).then(res => {
                if(res.status === 200){
                    this.setState({reset: true})
                }
            })
        }
    }

    render() {
        return (
            <div className="columnContainerCentered">
                <img src={logo} alt="Logo" style={{width: 128, height: 128, marginBottom: 20}}/>
                {!this.state.reset && <h3>Passwort zurücksetzen</h3>}
                {this.state.reset && <h3>Dein Passwort wurde zurückgesetzt.</h3>}
                {!this.state.reset && <p>Bitte gib deine E-Mail Adresse an.</p>}
                {this.state.reset && <p>Bitte prüfe dein E-Mail Postfach.</p>}
                <input onChange={(e) => this.handleChange(e)} className="m10" placeholder="Deine E-Mail"/>
                <button onClick={() => {this.reset()}} disabled={!this.inputValid()}  className="m10">Passwort Anfordern</button>
            </div>
        );
    }
}

export default ResetScreen;
