import React from "react";
import {confirmOrder, getOrder, getPpOrder} from "./OrderService";
import '../App.css';
import ReactDOM from 'react-dom';
import logo from "../img/blackdoor_white.jpeg";
import {BallBeat} from 'react-pure-loaders';


let PayPalButton = window.paypal.Buttons.driver('react', {React, ReactDOM});


class PaymentScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {order: null, overall: 0, loading: true, error: null};
    }

    componentDidMount() {
        const queryString = window.location.search;
        let u = null;
        let oid = null;

        if (queryString && this.parseQuery(queryString) && this.parseQuery(queryString).oid) {
            this.setState({oid: this.parseQuery(queryString).oid})
            oid = this.parseQuery(queryString).oid;
        }

        if (queryString && this.parseQuery(queryString) && this.parseQuery(queryString).u) {
            this.setState({u: this.parseQuery(queryString).u});
            u = this.parseQuery(queryString).u;
        }


        if (oid && u) {
            // Get the Blackdoor Order that was created once the user ordered within the App and got redirected.
            getOrder(this.parseQuery(queryString).oid).then(r => {
                r.json().then(x => {

                    if (x.code === 1) {
                        this.setState({error: "Es ist ein Fehler aufgetreten. Bitte versuche es erneut."})
                        return;
                    }

                    let overall = 0;

                    x.orderedItems.forEach(i => {
                        console.log(i.article.tax, (i.article.price / (1 + i.article.tax)), );
                        // Weird calculation to avoid wrong calculations (cent range) in sevdesk because of taxes.
                        overall = overall + (i.amount * Math.round((i.article.price / (1 + i.article.tax)) * 100) / 100 * ( 1 + i.article.tax))
                    });


                    this.setState({order: x, overall: overall})
                    // Check if there was already a payment for this order id.
                    getPpOrder(this.parseQuery(queryString).oid).then(r => {
                        r.json().then(x => {
                                if (x.length > 0) {
                                    this.setState({alreadyPayed: true, error: null})
                                }
                                this.setState({loading: false})
                            }
                        )
                    });

                });
            });
        }
    }

    finishOrder(pId, actions, data) {
        console.log("ACTIONS:", actions)
        //actions.order.capture().then((details) => {
            //console.log("DETAILS:", details)
            this.setState({loading: true})
            confirmOrder(pId, this.state.u, this.state.order._id).then(r => {
                r.json().then(x => {
                    // Check if there was already a payment for this order id.
                    getPpOrder(this.state.order._id).then(r => {
                        r.json().then(x => {
                                if (x.length > 0) {
                                    this.setState({alreadyPayed: true, error: null})
                                } else {
                                    this.setState({error: "Es ist ein Fehler aufgetreten. Bitte versuche es erneut."})
                                }
                                this.setState({loading: false})
                            }
                        )
                    });

                });
            });
        //});

    }

    parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: Math.round(this.state.overall * 100) / 100
                }
            }]
        });
    }

    onApprove(data, actions) {
        this.finishOrder(data.orderID, actions, data);
    }

    render() {
        return (
            <div>
                <img src={logo} alt="Logo" style={{width: 128, height: 128, marginBottom: 20}}/>

                {(this.state.loading && !this.state.error) && <div style={{margin: 15}}><BallBeat
                    color={'#222'}
                    loading={this.state.loading}
                /></div>}

                {this.state.error && <h3 className="header">{this.state.error}</h3>}
                {(!this.state.loading && !this.state.order && !this.state.error) &&
                <p className="header">Keine Bestellung gefunden.</p>}
                {(!this.state.loading && this.state.order && this.state.alreadyPayed && !this.state.error) &&
                <p className="header">Vielen Dank für deine Bestellung und Unterstützung. Wir freuen uns auf dich!</p>}

                {(!this.state.loading && this.state.order && this.state.alreadyPayed && !this.state.error) &&
                <p className="header" style={{fontWeight: 'normal'}}>Bitte überprüfe ggf. deinen E-Mail Posteingang und
                    kehre bei Bestellungen aus der App in diese zurück.</p>}

                {(!this.state.loading && this.state.order && !this.state.alreadyPayed && !this.state.error) && <div>
                    <p className="header">Deine Warenkorb</p>
                    <div>
                        <div>
                            {this.state.order.orderedItems.map((item, index) => (
                                <p style={{fontSize: 12}}>{item.amount}x {item.article.title} ({item.amount * item.article.price}€)</p>
                            ))}
                        </div>
                        <div>
                            <p style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                margin: 20
                            }}>Gesamt {Math.round(this.state.overall * 100) / 100}€ (Inkl. Mwst.)</p>
                        </div>
                        {(this.state.overall > 0) && <PayPalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                        />}
                    </div>
                </div>}
            </div>
        );
    }
}

export default PaymentScreen;
