import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import React from "react";
import ReactDOM from 'react-dom';
import { Switch } from "react-router-dom"
import Imprint from "./Imprint";
import PaymentScreen from "./payment/PaymentScreen";
import ResetScreen from "./user/ResetScreen";


function App() {
    return (
        <div className="App">
            <Router>
                <div style={{alignContent: 'center', justifyContent: 'center'}}>
                    <Switch>
                        <Route path="/pp">
                            <div>
                                <PaymentScreen/>
                            </div>
                        </Route>
                        <Route path="/reset">
                            <div>
                                <ResetScreen/>
                            </div>
                        </Route>
                        <Route path="/imprint">
                                <Imprint/>
                        </Route>
                        <Route path="/">
                            <div><p>BD</p></div>
                        </Route>
                    </Switch>
                </div>
                <a style={{fontSize: 12, textDecoration: 'none', color: '#222', margin: 20}}
                   href="https://blackdoor-music-fest.de/imprint"
                >Impressum / Datenschutz / AGBs</a>
            </Router>
        </div>
    );
}

export default App;
